import React from 'react'
import parse from 'html-react-parser'

const Default = ({ page }) => {

  return (
    <div className={`faq faqContainer default`}>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className={`wpContent`}>
        {parse(page.content || '')}
      </div>

    </div>
  )
}
export default Default