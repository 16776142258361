import React from 'react'
// import React, { useContext } from "react"
// import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
// import { GlobalStateContext } from "../context/GlobalContextProvider"

function SvgFilter() {
  // const dispatch = useContext(GlobalDispatchContext)
  // const stateCtx = useContext(GlobalStateContext)

  return (
    <>
      <svg className={`svgFilterInner`} style={{ position: 'absolute', width:'0',height:'0'}}>
        <def>
          <filter id="svgBlur" x={`0%`} y={`0%`} width={`100%`} height={`100%`}>
            <feGaussianBlur
              result="blurOut2"
              in="SourceGraphic"
              stdDeviation="10"
            >
              {/* <animate
                    attributeType="XML"
                    attributeName="stdDeviation"
                    dur="666ms"
                    reapeatCount="1"
                    begin="1000"
                    calcMode="linear"
                    values="3 ; 0 ; 0 ; 0.15 ; 0.25 ; 5.5 ; 4.4 ; 3.3 ; 1.3 ; 0.4 ; 10"
                    keyTimes="0 ; 0.03 ; 0.23 ; 0.3 ; 0.4 ; 0.5 ; 0.6 ; 0.7 ; 0.8 ; 0.9 ; 1"
                />   */}
              {/* <animate
                    attributeType="XML"
                    attributeName="stdDeviation"
                    dur="1666ms"
                    reapeatCount="1"
                    begin="0"
                    calcMode="linear"
                    values="2 ; 5 ; 10 ; 100 ; 20 ; 10 ; 5 ; 2 ; 2"
                    keyTimes="0 ; 0.3 ; 0.48 ; 0.52 ; 0.56 ; 0.66 ; 0.8 ; 0.88 ; 1"
                />   */}
            </feGaussianBlur>

            <feComposite in="SourceGraphic" in2="blurOut2" operator="arithmetic" k1="0.1" k2="0.1" k3="0.5" k4="0">

            </feComposite>
          </filter>
        </def>
      </svg>
    </>
  )
}
export default SvgFilter