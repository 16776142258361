import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'

// import {
//   GlobalStateContext,
// } from 'src/context/GlobalContextProvider'


const FaqPage = ({page, pageContext}) => {
  const graphqlResult = useStaticQuery(graphql`
    query FaqQuery {
      faqPage: wpPage(AcfGatsbyId: {pageid: {eq: "faq"}}){
        acfpagefaq {
          beforeListPageFaq          
        }
      }
      allWpCpttxFaq {
        edges {
          node {
            name        
            cPTFaqs {
              nodes {
                title
                slug
                content
              }
            }
          }
        }
      }
    }
  `)
  // const state = useContext(GlobalStateContext)

  const taxonomies = graphqlResult.allWpCpttxFaq.edges
  const[itemOpen, setItemOpen] = useState({ group: 0, item: 0 })

  const prevPath = pageContext.siteSlug

  // const activeId = `faq-g${itemOpen.group}-i${itemOpen.item}`

  // useEffect(() => {
  //   const timer = (itemOpen.group === 0 && itemOpen.item === 0) ?
  //   () => {} :
  //   setTimeout(() => {
  //     const target = typeof document !== `undefined` ? document.getElementById(activeId) : false
  //     target && target.scrollIntoView({
  //       behavior: "smooth",
  //       // block: `${state.wndowWidth < 740 ? 'start' : 'center'}`
  //       block: 'start'
  //     })
  //     // console.log(activeId)
  //     // console.log(itemOpen)
  //   }, 688)
  //   return () => clearTimeout(timer)
  // }, [itemOpen, state.wndowWidth])

  return (
    <>
      <div className={`faq faqContainer`}>

        <div className="section-header">
          <h1>
            <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
              {page.title}
            </span>
          </h1>
          <h3>{graphqlResult.faqPage.acfpagefaq.beforeListPageFaq}</h3>

          <div className={`faqNav`}>

            <ul>
              {taxonomies && taxonomies.map((title, key) => {
                return(
                  <li 
                    key={key}
                    className={`cta2`}
                  >
                    <AnchorLink
                      to={`#${title.node.name}`}
                      title={title.node.name}
                      gatsbyLinkProps={{state: {prevPath: prevPath}}}
                    >
                      {title.node.name}
                    </AnchorLink>
                  </li>
                )
              })}
            </ul>

          </div>

        </div>

        <div className={`faqList`}>
          <div className={`faqListINT`}>
            {taxonomies && taxonomies.map((group, i) => {
              group.node.cPTFaqs.nodes.map((node) => {
                return(
                  node.groupIND = i
                )
              })
              return(
                <div
                  key={i}
                  className={`faqGroup`}
                  id={`${group.node.name}`}
                >
                  <h2>
                    {group.node.name}
                  </h2>

                  {group.node.cPTFaqs.nodes && group.node.cPTFaqs.nodes.map((item, i) => {
                    return(
                      <div
                        key={i}
                        id={`faq-g${item.groupIND + 1}-i${i + 1}`}
                        className={`contentItem group${item.groupIND + 1} item${i + 1} ${(itemOpen.group === item.groupIND + 1 && itemOpen.item === i + 1) ? 'openItem' : ''}`}
                        onClick={() => 
                          {setItemOpen(
                            (itemOpen.group === item.groupIND + 1 && itemOpen.item === i + 1) ?
                            { group: 0, item: 0 } :
                            { group: item.groupIND + 1, item: i + 1 }
                          )}}
                        onKeyDown={() => 
                          {setItemOpen(
                            (itemOpen.group === item.groupIND + 1 && itemOpen.item === i + 1) ?
                            { group: 0, item: 0 } :
                            { group: item.groupIND + 1, item: i + 1 }
                          )}}
                        role="button"
                        tabIndex="0"
                        aria-label="show content"
                      >                        
                        <h3 className={`faqQuestion`}>
                          <span className={`itemNR`}>
                            {i + 1} 
                          </span>
                          <span className={`itemTitle`}>
                            {item.title}
                          </span>
                          <FontAwesomeIcon
                            className={`faqArrow`}
                            icon={
                              (itemOpen.group === item.groupIND + 1 && itemOpen.item === i + 1) ? faChevronDown : faChevronUp
                            }
                          >
                          </FontAwesomeIcon>
                        </h3>

                        <div 
                          className={`contentItemWrap`} 
                          style={
                            (itemOpen.group === item.groupIND + 1 && itemOpen.item === i + 1) ?
                            {transform: 'translateY(0)', maxHeight: '666px', transitionDelay: '48ms', opacity: '1'} :
                            {transform: 'translateY(-555px)', maxHeight: '0', transitionDelay: '0ms', opacity: '0'}
                          }
                        >
                          {item.content && <div dangerouslySetInnerHTML={{ __html: item.content }}></div>}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>

        <div className={`wpContent`}>
          {page.content && <div dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        </div>

      </div>
    </>
  )
}
export default FaqPage