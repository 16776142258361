import React, { useContext, useEffect } from 'react'
import { 
  GlobalDispatchContext 
} from 'src/context/GlobalContextProvider'

function MouseListener() {
  const dispatch = useContext(GlobalDispatchContext)

  const updateMousePosition = ev => {
    dispatch({ type: 'MOUSE_X', value: ev.clientX })
    dispatch({ type: 'MOUSE_Y', value: ev.clientY })
  }
  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition)
    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    </>
  )
}
export default MouseListener