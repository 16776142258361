import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useStaticQuery, graphql } from "gatsby"


const NotFound = ({ page, pageContext, location }) => {

  const graphqlResult = useStaticQuery(graphql`
    query notFound {
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      siteUrl: site {
        siteMetadata {
          siteUrl
        }
        pathPrefix
      }
    }
  `)
  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo
  const pathPrefix = graphqlResult.siteUrl.pathPrefix  //  returns slug with NO '/' on end
  const siteUrl = graphqlResult.siteUrl.siteMetadata.siteUrl  //  returns slug with '/' on end
  // const siteUrl = 'https://dev.modernformspl.mf-admin.pl/'                                 //    DEV URL


  useEffect(() => {
    if(location && location.search) {
      const newUrl = location.search.startsWith('?') ? 
        `${siteUrl}${pathPrefix}/${
          location.search.substring( location.search.indexOf('search=/') + 8 )
        }` 
      : null
      window.history.replaceState({}, {}, newUrl)
    }
  },[location])

  // console.log('__NOTFOUND COMPONENT', location)


  return (
    <div className={`faq faqContainer default`}>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className={`wpContent`}>
        {parse(page.content || '')}
      </div>

      <div>
        <AniLink
          className="link button fakeButton cta"
          style={{marginTop:'60px', marginbottom: '40px'}}
          to={`/${pageContext.realSlugs[pageContext.siteSlug]}`}
          swipe
          direction="up"
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
          duration={1.6}
        >
          {currentLang === 'pl-PL' ? `Strona główna` : currentLang === 'de-DE' ? 'Zuhause' : 'Home page'}
        </AniLink>
      </div>

    </div>
  )
}
export default NotFound