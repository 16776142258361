import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import howToOrderIco1 from 'src/images/stepsIcons/icon-jakzamawiac-krok1.svg'
import howToOrderIco2 from 'src/images/stepsIcons/icon-jakzamawiac-krok2.svg'
import howToOrderIco3 from 'src/images/stepsIcons/icon-jakzamawiac-krok3.svg'
import howToOrderIco4 from 'src/images/stepsIcons/icon-jakzamawiac-krok4.svg'
import howToOrderIco5 from 'src/images/stepsIcons/icon-jakzamawiac-krok5.svg'
import howToOrderIco6 from 'src/images/stepsIcons/icon-jakzamawiac-krok6.svg'
import howToOrderIco7 from 'src/images/stepsIcons/icon-jakzamawiac-krok7.svg'
import howToOrderIco8 from 'src/images/stepsIcons/icon-jakzamawiac-krok8.svg'

const HowToOrder = ({ page, pageContext }) => {

  const graphqlResult = useStaticQuery(graphql`
    query HowToOrderPosts {
      howToOrder: wpPage(AcfGatsbyId: {pageid: {eq: "how-to-order"}}) {
        acfpagehowtoorder {
          ctaLinkPageHowToOrder
          ctaTextPageHowToOrder
          step1PageHowToOrder
          step2PageHowToOrder
          step3PageHowToOrder
          step4PageHowToOrder
          step5PageHowToOrder
          step6PageHowToOrder
          step7PageHowToOrder
          step8PageHowToOrder
          mailLabelPageHowToOrder
        }
      }
      sportOrder: wpCptSportPage(AcfGatsbyId: {pageid: {eq: "placing-order"}}) {
        acfsportpageplacingorder {
          cta2LinkSportPagePlacingOrder
          cta2TextSportPagePlacingOrder
          ctaLinkSportPagePlacingOrder
          ctaTextSportPagePlacingOrder
          subheader2SportPagePlacingOrder
          subheaderSportPagePlacingOrder
        }
        slug
      }
      businessOrder: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "placing-order"}}) {
        acfbusinesspageplacingorder {
          cta2LinkBusinessPagePlacingOrder
          cta2TextBusinessPagePlacingOrder
          ctaLinkBusinessPagePlacingOrder
          ctaTextBusinessPagePlacingOrder
          subheader2BusinessPagePlacingOrder
          subheaderBusinessPagePlacingOrder
        }
        slug
      }
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      defaultMail: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-email"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)

  const phoneHref = graphqlResult.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')

  const orderPlacing = graphqlResult.howToOrder.acfpagehowtoorder

  const order = pageContext.sitePath === '/sport/' ? graphqlResult.sportOrder.acfsportpageplacingorder : graphqlResult.businessOrder.acfbusinesspageplacingorder

  // console.log(pageContext)


  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--howtoorder">
        
        {pageContext.sitePath !== '/brand/' && <AniLink
          className="howToOrder-close"
          // to={`${pageContext.sitePath}${pageContext.sitePath === '/sport/' ? graphqlResult.sportOrder.slug : graphqlResult.businessOrder.slug}`}
          to={`/${pageContext.realSlugs[pageContext.siteSlug]}/${pageContext.sitePath === '/sport/' ? graphqlResult.sportOrder.slug : graphqlResult.businessOrder.slug}`}
          duration={1.6}
          swipe
          direction="down"
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </AniLink>}

        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco1} />
          </div>
          {parse(orderPlacing.step1PageHowToOrder || '')}
        </div>
        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco2} />
          </div>
          {parse(orderPlacing.step2PageHowToOrder || '')}
        </div>
        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco3} />
          </div>
          {parse(orderPlacing.step3PageHowToOrder || '')}
        </div>
        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco4} />
          </div>
          {parse(orderPlacing.step4PageHowToOrder || '')}
        </div>
        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco5} />
          </div>
          {parse(orderPlacing.step5PageHowToOrder || '')}
        </div>
        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco6} />
          </div>
          {parse(orderPlacing.step6PageHowToOrder || '')}
        </div>
        <div className="card">
          <div className={`cardIco`} style={{position:'absolute'}}>
            <img alt="decoration" src={howToOrderIco7} />
          </div>
          {parse(orderPlacing.step7PageHowToOrder || '')}
        </div>
        {orderPlacing.step8PageHowToOrder && 
          <div className="card">
            <div className={`cardIco`} style={{position:'absolute'}}>
              <img alt="decoration" src={howToOrderIco8} />
            </div>
            {parse(orderPlacing.step8PageHowToOrder || '')}
          </div>
        }
        
      </div>
      <div className="box box--order box--order-howToOrder">

        {pageContext.sitePath === '/sport/' ? 
        <>
        <h2>{order.subheaderSportPagePlacingOrder}</h2>
        <h3>{order.subheader2SportPagePlacingOrder}</h3>
        </> 
        : 
        <>
        <h2>{order.subheaderBusinessPagePlacingOrder}</h2>
        <h3>{order.subheader2BusinessPagePlacingOrder}</h3>
        </> 
        }

        <a
          className={`link fakeButton cta ${pageContext.sitePath === '/brand/' ? 'button' : ''}`}
          href={`tel:${phoneHrefClean}`}

          data-hover={pageContext.sitePath === '/sport/' ? order.ctaTextSportPagePlacingOrder : order.ctaTextBusinessPagePlacingOrder}

        >

          <span>{pageContext.sitePath === '/sport/' ? order.ctaTextSportPagePlacingOrder : order.ctaTextBusinessPagePlacingOrder}</span>

        </a>

        <h3>{orderPlacing.mailLabelPageHowToOrder}</h3>

        <div style={{marginBottom:'40px', display:'flex',justifyContent:'center'}}>
          <a
            className={`cta2`}
            href={`mailto:${graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo}`}
            style={{color:'white',textDecoration:'none'}}
          >
            <span>{graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo}</span>
          </a>
        </div>


        {pageContext.sitePath !== '/brand/' && <AniLink
          className="link-back cta2"
          // to={`${pageContext.sitePath}${pageContext.sitePath === '/sport/' ? graphqlResult.sportOrder.slug : graphqlResult.businessOrder.slug}`}
          to={`/${pageContext.realSlugs[pageContext.siteSlug]}/${pageContext.sitePath === '/sport/' ? graphqlResult.sportOrder.slug : graphqlResult.businessOrder.slug}`}
          swipe
          direction="down"
          duration={1.6}
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
        >
          {pageContext.currentLang === 'pl-PL' ? 'powrót' : pageContext.currentLang === 'de-DE' ? 'zurück' : 'go back'}
        </AniLink>}

      </div>
    </div>
  )
}
export default HowToOrder