import React from 'react'
import Regions from 'src/components/shippingData/regions'
import 'src/components/shippingData/shipping.css'
import MouseListener from 'src/components/tools/MouseListener'

const Shipping = ({ page }) => {
  const pageContentHtml = page.content

  return (
    <>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>

      <div id="shipping" className={`shipping`}>
        <Regions pageContentHtml={pageContentHtml} />
      </div>
      <MouseListener />
    </>
  )
}
export default Shipping
