import React, { useEffect } from 'react'
import { graphql } from 'gatsby'


import BrandLayout from 'src/components/layouts/brandLayout'
import SportLayout from 'src/components/layouts/sportLayout'
import BusinessLayout from 'src/components/layouts/businessLayout'

import Seo from 'src/components/seoWp'

import Default from 'src/components/sharedPages/default'
import Faq from 'src/components/sharedPages/faq'
import Shipping from 'src/components/sharedPages/shipping'
import HowToOrder from 'src/components/sharedPages/howToOrder'
import NotFound from 'src/components/sharedPages/notFound'

import 'src/styles/brand.scss'


const PageTemplate = ({ data: { page }, pageContext, location }) => {


  // console.log('__SHARED TEMPLATE', location)

  /*      CHECK 404 SEARCH PARAM      */
  const is404search = location.search !== '' ? 
    ( location.search.substring(1).startsWith('sport') ? 
      'sport' 
      : ( location.search.substring(1).startsWith('business') ? 
          // 'business' : false 
          'business' : 'brand' 
        )
    ) 
    : false

  /*      CHECK LOCATION.STATE.PREVPATH     */
  const statePrevPath = (typeof location.state !== 'undefined' && location.state !== null) && (typeof location.state.prevPath !== 'undefined' && location.state.prevPath !== null) ? { site: location.state.prevPath } : { site: false }

  /*      CHECK localStorage GETITEM      */
  const localStorageLoco = (typeof localStorage !== 'undefined' && localStorage !== null) ? localStorage.getItem("loco") : null

  /*      UPDATEING    */
  const locationHold = () => {

    if(statePrevPath.site !== false) {
      window.localStorage.setItem("loco", statePrevPath.site)
    }

    if((localStorageLoco === null) && (statePrevPath.site !== false)) {
      window.localStorage.setItem("loco", statePrevPath.site)
    }

    if((localStorageLoco !== null) && (statePrevPath.site === false)) {
      statePrevPath.site = localStorageLoco
    }

    if((localStorageLoco !== null) && (statePrevPath.site !== false)) {
      window.localStorage.setItem("loco", statePrevPath.site)
    }

  }
  useEffect(() => {
    locationHold()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  /*****      USE: is404search || .site || localStorageLoco     *****/
  const prevPath =   
    (is404search === false) ? 
      (statePrevPath.site === false) ?      
        (localStorageLoco === null) ?
          'brand'
        : localStorageLoco
      : statePrevPath.site
    : is404search



  pageContext.siteSlug = prevPath
  pageContext.sitePath = `/${prevPath}/`

  if(pageContext.sitePath === 'business') {
    require('src/styles/business.scss') 
  } else if (pageContext.sitePath === 'sport') {
    require('src/styles/sport.scss') 
  } else {
    require('src/styles/brand.scss')   
  }


  const Layouts = {
    brand: BrandLayout,
    sport: SportLayout,
    business: BusinessLayout
  }
  const Layout = prevPath in Layouts ? Layouts[prevPath] : BrandLayout

  const contentMap = {
    faq: Faq,
    default: Default,
    shipping: Shipping,
    'how-to-order': HowToOrder,
    '404': NotFound
  }
  const Content = page.AcfGatsbyId.pageid in contentMap ? contentMap[page.AcfGatsbyId.pageid] : Default


  return (
    <>
      <Layout pageContext={pageContext} page={page} >
        <Seo 
          seo={page.seo} 
          site={prevPath} 
          slug={page.slug} 
          gatsbyId={page.AcfGatsbyId.pageid} 
          subpageid={typeof pageContext.subpage !== 'undefined' && pageContext.subpage.AcfGatsbyId.pageid} 
          shared={true}
        />
        <Content pageContext={pageContext} page={page} location={location} />
      </Layout>
    </>
  )
}
export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      slug
      id
      title
      content
      AcfGatsbyId {
        pageid
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
        schema {
          raw
        }
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid (maxWidth: 1600, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`