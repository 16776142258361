import React, { useState, useContext, useEffect } from 'react'
import RegionsList from 'src/components/shippingData/regionsList'
import { useStaticQuery, graphql } from 'gatsby'

import { shippingEurope } from "../../../static/shippingData/europe";
import { shippingAsia } from "../../../static/shippingData/asia";
import { shippingAfrica } from "../../../static/shippingData/africa";
import { shippingAustralia } from "../../../static/shippingData/australia";
import { shippingNorthAmerica } from "../../../static/shippingData/northAmerica";
import { shippingSouthAmerica } from "../../../static/shippingData/southAmerica";
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'


const Regions = ({ pageContentHtml }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const graphqlResult = useStaticQuery(graphql`
    query ShippingDataData {
      shipping: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "shipping-labels"}}) {      
        acfshippinglabels {
          continentLabelShippingLabels
          countryLabelShippingLabels
          shippingTimeLabelShippingLabels
          priceLabelShippingLabels
          infoField1ShippingLabels
          infoField2ShippingLabels
          infoField3ShippingLabels
          infoField4ShippingLabels
          dropdownBlankCountryShippingLabels
          dropdownBlankContinentShippingLabels
          africaLabelShippingLabels
          asiaLabelShippingLabels
          australiaLabelShippingLabels
          europeLabelShippingLabels
          northAmericaLabelShippingLabels
          southAmericaLabelShippingLabels
          tooltipShippingLabels
          tooltipAltShippingLabels
        }
      }
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const labels = graphqlResult.shipping.acfshippinglabels
  // console.log(labels.dropdownBlankContinentShippingLabels)

  const continentList = {
    EU: labels.europeLabelShippingLabels, 
    AS: labels.asiaLabelShippingLabels, 
    AF: labels.africaLabelShippingLabels, 
    AU: labels.australiaLabelShippingLabels, 
    NA: labels.northAmericaLabelShippingLabels,
    SA: labels.southAmericaLabelShippingLabels
  }

  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const[selectedList, setSelectedList] = useState(shippingEurope)

  /*    CHECK ARRAYS WITH SHIPPING DATA IF THERE aRE EMPTY ONES   */
  // const [checkContinents, setCheckContinents] = useState(false)
  // function checkShippingDataContinents() {
  //   setCheckContinents(true)
  //   dispatch({ type: 'JSON_CHECK_LIST', value: {
  //     EU: shippingEurope.length === 0 ? true : false,
  //     AS: shippingAsia.length === 0 ? true : false,
  //     AF: shippingAfrica.length === 0 ? true : false,
  //     AU: shippingAustralia.length === 0 ? true : false,
  //     NA: shippingNorthAmerica.length === 0 ? true : false,
  //     SA: shippingSouthAmerica.length === 0 ? true : false
  //   }})
  //   console.log('KONTYNENTS')
  // }
  // useEffect(() => {
  //   !checkContinents && checkShippingDataContinents()
  // }, [])  // eslint-disable-line react-hooks/exhaustive-deps


  function setDataEU(e) {
    e.stopPropagation()
    state.continent !== continentList.EU 
    ?
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.EU })
    : 
    closeDropdown()
  }
  function setDataAS(e) {
    e.stopPropagation()
    state.continent !== continentList.AS 
    ?
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.AS })
    : 
    closeDropdown()
  }
  function setDataAF(e) {
    e.stopPropagation()
    state.continent !== continentList.AF 
    ?
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.AF })
    : 
    closeDropdown()
  }
  function setDataAU(e) {
    e.stopPropagation()
    state.continent !== continentList.AU
    ?
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.AU })
    : 
    closeDropdown()
  }
  function setDataNA(e) {
    e.stopPropagation()
    state.continent !== continentList.NA 
    ?
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.NA })
    : 
    closeDropdown()
  }
  function setDataSA(e) {
    e.stopPropagation()
    state.continent !== continentList.SA 
    ?
    dispatch({ type: 'SHIPPING_CONTINENT', value: continentList.SA })
    : 
    closeDropdown()
  } 
  
  function renderPortalContent(continent) {

    switch(continent) {

      case continentList.EU:
          setSelectedList(shippingEurope)
          setCount(shippingEurope.length)
          setStart(shippingEurope[0])
          break

      case continentList.AS:
          setSelectedList(shippingAsia)
          setCount(shippingAsia.length)
          setStart(shippingAsia[0])
          break

      case continentList.AF:
          setSelectedList(shippingAfrica)
          setCount(shippingAfrica.length)
          setStart(shippingAfrica[0])
          break

      case continentList.AU:
          setSelectedList(shippingAustralia)
          setCount(shippingAustralia.length)
          setStart(shippingAustralia[0])
          break

      case continentList.NA:
          setSelectedList(shippingNorthAmerica)
          setCount(shippingNorthAmerica.length)
          setStart(shippingNorthAmerica[0])
          break

      case continentList.SA:
          setSelectedList(shippingSouthAmerica)
          setCount(shippingSouthAmerica.length)
          setStart(shippingSouthAmerica[0])
          break
          
      default:
        // console.log('DEFAULT')
    }
          
    closeDropdown()          
  }

  useEffect(() => {
    renderPortalContent(state.continent)
  }, [state.continent])  // eslint-disable-line react-hooks/exhaustive-deps
  
  function countInitial() {
    return shippingEurope.length
  }
  function startInitial() {
    return shippingEurope[0]
  }

  const[count, setCount] = useState(() => countInitial())
  const[start, setStart] = useState(() => startInitial())
  
  const[dropdown, setDropdown] = useState({
    // dropdownHeight: "54px", 
    dropdownHeight: "68px", 
    dropdownItemHeight: "0px",
    dropdownItemMargin:"0px", 
    // shutterHeight: '54px', 
    shutterHeight: '68px', 
    shutterZi: '10', 
    shutterOp: '1', 
    border: false,
  })

  function openDropdown(e) {
    setDropdown(prevState => {
      return { ...prevState, 
        dropdownHeight: `440px`, 
        // dropdownItemHeight: "54px", 
        dropdownItemHeight: "68px", 
        dropdownItemMargin:"4px", 
        shutterHeight: '0px', 
        shutterZi: '0', 
        shutterOp: '0', 
        border: true 
      }
    });
  }
  function closeDropdown(e) {
    setDropdown(prevState => {
      return { ...prevState, 
        // dropdownHeight: "54px", 
        dropdownHeight: "68px", 
        dropdownItemHeight: "0px", 
        dropdownItemMargin:"0px", 
        // shutterHeight: '54px', 
        shutterHeight: '68px', 
        shutterZi: '10', 
        shutterOp: '1', 
        border: false 
      }
    });
  }

  const dropdownItemStyle = {
    position: 'relative',
    zIndex: '5', 
    cursor: 'pointer', 
    display: 'flex', 
    alignItems: 'center', 
    marginBottom: `${dropdown.dropdownItemMargin}`,
    height: `${dropdown.dropdownItemHeight}`
  }

  const dropdownItemStyleINNER = {
    display: 'block', 
    width: '100%', 
    // height: `${dropdown.dropdownItemHeight / 2}`
  }

  // console.log(state.continent)
  
  return (
    <>
      <div className={`shippingForm`}>

        <div className={`regions shippingGrid row1x radiusUp ${dropdown.shutterHeight === '0px' ? 'dropOpen' : ''}`}>
        
          <div className={`shippingItem sName shippingLabel`}>
            <h5>{labels.continentLabelShippingLabels}</h5>
          </div>

          <div className={`shippingItem sName shippingDisplay dropdownEXT`}>
            <div
              className={`dropdown row2x`}
              style={{
                height: 'auto',
                zIndex: '1001'
                // zIndex: '0'
              }} 
            >
              <FontAwesomeIcon
                className="dropdown_top-arrow"
                icon={dropdown.shutterOp === '1' ? faChevronDown : faChevronUp}
                onClick={dropdown.border ? closeDropdown : openDropdown}
                role="button" 
                tabIndex="0"
                onKeyDown={dropdown.border ? closeDropdown : openDropdown}
              />
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  zIndex: dropdown.shutterZi,
                  top: '0',
                  left: '0',
                  height: dropdown.shutterHeight,
                  opacity: dropdown.shutterOp,
                  display: 'flex',
                  alignItems: 'center'
                }}
                onFocus={openDropdown}
                onBlur={closeDropdown}
                role="button" 
                tabIndex="0"
                onKeyDown={openDropdown}
                aria-label="dropdown"
                className={`dropdownPlaceholder cta2`}          
              >
                <span 
                  style={
                    {display:'block',width:'100%'}
                  } 
                  data-data={state.continent}
                >
                    {/* {state.continent} */}
                    {state.continent === '' ? continentList.EU : state.continent}
                  </span>
              </div>
              <div 
                id="continentSelector" 
                onFocus={openDropdown} 
                onBlur={closeDropdown} 
                role="button" 
                tabIndex="0"
                style={{ 
                  height: dropdown.dropdownHeight,
                  overflowY: 'hidden',
                }}
                onKeyDown={openDropdown}
                className={`${dropdown.border ? 'border' : ''}`}
              >
                <div
                  onClick={setDataEU}
                  role="button" 
                  tabIndex="0" 
                  onKeyDown={setDataEU}
                  style={dropdownItemStyle}
                  className={`cta2`}
                >
                  <span 
                    style={dropdownItemStyleINNER}
                  >
                    {continentList.EU}
                  </span>
                </div>
                <div
                  onClick={setDataAS}
                  role="button" 
                  tabIndex="0" 
                  onKeyDown={setDataAS}
                  style={dropdownItemStyle}
                  className={`cta2`}
                >
                  <span 
                    style={dropdownItemStyleINNER}
                  >
                    {continentList.AS}
                  </span>
                </div>
                <div
                  onClick={setDataAF}
                  role="button" 
                  tabIndex="0" 
                  onKeyDown={setDataAF}
                  style={dropdownItemStyle}
                  className={`cta2`}
                >
                  <span 
                    style={dropdownItemStyleINNER}
                  >
                    {continentList.AF}
                  </span>
                </div>
                <div
                  onClick={setDataAU}
                  role="button" 
                  tabIndex="0" 
                  onKeyDown={setDataAU}
                  style={dropdownItemStyle}
                  className={`cta2`}
                >
                  <span 
                    style={dropdownItemStyleINNER}
                  >
                    {continentList.AU}
                  </span>
                </div>
                <div
                  onClick={setDataNA}
                  role="button" 
                  tabIndex="0" 
                  onKeyDown={setDataNA}
                  style={dropdownItemStyle}
                  className={`cta2`}
                >
                  <span 
                    style={dropdownItemStyleINNER}
                  >
                    {continentList.NA}
                  </span>
                </div>
                <div
                  onClick={setDataSA}
                  role="button" 
                  tabIndex="0" 
                  onKeyDown={setDataSA}
                  style={dropdownItemStyle}
                  className={`cta2`}
                >
                  <span 
                    style={dropdownItemStyleINNER}
                  >
                    {continentList.SA}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RegionsList shippingData={selectedList} count={count} start={start} labels={labels} pageContentHtml={pageContentHtml} currentLang={currentLang} />

      </div>

    </>
  )
}
export default Regions