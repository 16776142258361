import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'

const Regions = ({ shippingData, count, start, labels, pageContentHtml, currentLang }) => {
  const state = useContext(GlobalStateContext)
  const countryLangKey = `country${currentLang.slice(currentLang.lastIndexOf('-') + 1)}`

  const [emptyListMode, setEmptyListMode] = useState(false)
  useEffect(() => {
    setEmptyListMode(shippingData.length === 0 ? true : false)
  }, [shippingData])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLocalState({ country: !emptyListMode ? labels.dropdownBlankCountryShippingLabels : '- - - -' });
  }, [start, labels.dropdownBlankCountryShippingLabels, emptyListMode]);

  const [localState, setLocalState] = useState({ country: labels.dropdownBlankCountryShippingLabels })
  const country = localState.country
  const time = localState.time


  // const price = localState.price

  const price = currentLang === 'pl-PL' && typeof localState.price !== 'undefined' ? localState.price : typeof localState.price !== 'undefined' ? (localState.price / 5).toFixed(2) : ''


  // /*    VISUAL EFECT FOR CONTINENT SELECTION    */
  // useLayoutEffect(() => {
  //   document.body.classList.add('continentSelected')
  //   const timer = setTimeout(() => {
  //     document.body.classList.remove('continentSelected')
  //   }, 3000);
  //   return () => clearTimeout(timer)
  // }, [start]);

  // /*    VISUAL EFECT FOR COUNTRY SELECTION    */
  // useLayoutEffect(() => {
  //   document.body.classList.add('countrySelected')
  //   const timer = setTimeout(() => {
  //     document.body.classList.remove('countrySelected')
  //   }, 3000);
  //   return () => clearTimeout(timer)
  // }, [localState]);

  /*    MAIN FUNCTIONALITY    */
  function displayRegion(e) {
    e.stopPropagation();
    setLocalState({country: e.currentTarget.getAttribute("data-country"), time: e.currentTarget.getAttribute("data-time"), price: e.currentTarget.getAttribute("data-price")})
    closeDropdown()
  }

  const[dropdown, setDropdown] = useState({
    dropdownHeight: "68px", 
    shutterHeight: '68px', 
    shutterZi: '10', 
    shutterOp: '1',
    transition: 333 + count * 10,
    border: false 
  })

  function openDropdown() {
    setDropdown(prevState => {
      return { ...prevState, 
        dropdownHeight: `${count * 72}px`, 
        shutterHeight: '0px', 
        shutterZi: '0', 
        shutterOp: '0', 
        transition: 333 + count * 10,
        border: true 
      }
    });
  }
  function closeDropdown() {
    setDropdown(prevState => {
      return { ...prevState, 
        dropdownHeight: "68px", 
        shutterHeight: '68px', 
        shutterZi: '10', 
        shutterOp: '1', 
        transition: 333 + count * 10,
        border: false 
      }
    });
  }



  /***************************************************/
  /*******    *SCROLL POSITION CONTROLL*    *********/
  // const [scrollOffer, setScrollOffer] = useState(0)
  // const sessionKey = `@@scroll|/${slug}/|`
  // function rememberScroll() {
  //   const sessionStorageX = (typeof sessionStorage !== 'undefined' && sessionStorage !== null) ? 
  //     sessionStorage
  //     : null
  //   const sessionScrolls = Object.keys(sessionStorageX)
  //   function findCurrentScroll(item) {
  //     return item.includes(sessionKey) 
  //   }
  //   const currentScrolls = sessionScrolls.filter(findCurrentScroll)
  //   const currentScrollsTrimed = currentScrolls.map((item) => {
  //     return item.slice(item.lastIndexOf('|') + 1)
  //   })
  //   const currentScrollsTrimedMax = Math.max.apply(null, currentScrollsTrimed);
  //   const currentSessionScroll = `${sessionKey}${currentScrollsTrimedMax}`
  //   setScrollOffer(sessionStorageX[currentSessionScroll])
  // }
  /********    *SCROLL POSITION CONTROLL*    *********/
  /**************************************************/
  /*    SCROLL TO TOP AFTER STATE CHANGE    */
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //   (dropdown.border && (scrollOffer)) &&
  //   window.scroll({
  //     top: scrollOffer,
  //     left: 0,
  //     behavior: 'smooth'
  //   }) 
  //   }, 400);
  //   return () => clearTimeout(timer)
  // }, [localState])


  useEffect(() => {
    const timer = setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 400);
    return () => clearTimeout(timer)
  }, [localState])

  // const [, forceUpdate] = useState();
  // useEffect(() => {
  //   const timer =
  //   setTimeout(forceUpdate, 1666);
  //   // console.log('FORCE UPDATE')
  //   return () => clearTimeout(timer)
  // }, [localState]);
  



  /*    CONTENT MEASURE    */
  function contentInitial() {
    return 0
  }
  const contentRef = useRef(null)
  const[contentHeight, setContentHeight] = useState(() => contentInitial())
  useLayoutEffect(() => {
    const cHeight = pageContentHtml ? contentRef.current.getBoundingClientRect() : null
    pageContentHtml && setContentHeight(parseInt(cHeight.height))
  }, [state.windowWidth, state.windowHeight]) // eslint-disable-line react-hooks/exhaustive-deps
  
  /*    WINDOW HEIGHT ACCORDING TO DROPDOWN HEIGHT    */
  let heightControl = dropdown.shutterHeight === '0px' ? ((count * 68) - (contentHeight + 200)) : 0

    
  return (
    <>
      <div className={`regions shippingGrid row1x fakeShadow radiusDown ${dropdown.shutterHeight === '0px' ? 'dropOpen' : ''}`}>

        <div className={`shippingItem sName shippingLabel ${emptyListMode ? 'emptyListMode' : ''}`}>
          <h5>{labels.countryLabelShippingLabels}</h5>
        </div>
        <div className={`shippingItem sName shippingDisplay dropdownEXT ${emptyListMode ? 'emptyListMode' : ''}`}>
          <div
            className={`dropdown row3x`}
            style={{
              height: 'auto',
              zIndex: '1000',
            }} 
          >
            {!emptyListMode && <FontAwesomeIcon
              className="dropdown_top-arrow"
              icon={dropdown.shutterOp === '1' ? faChevronDown : faChevronUp}
              onClick={dropdown.border ? closeDropdown : openDropdown}
              role="button" 
              tabIndex="0"
              onKeyDown={dropdown.border ? closeDropdown : openDropdown}
            />}
            <div
              style={{
                width: '100%',
                position: 'absolute',
                zIndex: dropdown.shutterZi,
                top: '0',
                left: '0',
                height: dropdown.shutterHeight, 
                opacity: dropdown.shutterOp,
                display: 'flex',
                alignItems: 'center',
                paddingRight: emptyListMode ? '8px' : '',                
              }}
              onFocus={openDropdown}
              onClick={openDropdown}
              onBlur={closeDropdown}
              role="button" 
              tabIndex="0"
              onKeyDown={openDropdown}
              aria-label="dropdown"
              className={`dropdownPlaceholder cta2`}          
            >
              <span style={{display:'block',width:'100%'}} data-data={country}>{country}</span>
            </div>
            <div 
              id="regionSelector" 
              onFocus={openDropdown} 
              onBlur={closeDropdown} 
              role="button" 
              tabIndex="0"
              style={{ 
                height: dropdown.dropdownHeight,
                overflowY: 'hidden',
                transition: `height ${dropdown.transition}ms ease-in-out`
              }}
              onKeyDown={openDropdown}
              className={`${dropdown.border ? 'border' : ''}`}
            >
              {shippingData && shippingData.map((data, key) => {
                return (
                  <div 
                    data-country={data[countryLangKey]} 
                    data-time={data.time} 
                    data-price={data.price} 
                    onClick={displayRegion} 
                    role="button" 
                    tabIndex="0" 
                    onKeyDown={displayRegion}
                    key={key}
                    className={`cta2`}
                    style={{position: 'relative',zIndex: '5', cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '4px'}}
                  >
                    <span style={{display:'block',width:'100%'}}>
                      {data[countryLangKey]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>        

        {emptyListMode ? 
        <>
          {/* <div className={`shippingItem sPrice shippingDisplay emptyListGridItem`}>
            <h5>{labels.dropdownBlankContinentShippingLabels}</h5>
          </div> */}
          <div className={`shippingItem sDesc shippingLabel ${emptyListMode ? 'emptyListMode' : ''}`}>        
            <h5 dangerouslySetInnerHTML={{ __html: labels.shippingTimeLabelShippingLabels }}></h5>
          </div>
          <div className={`shippingItem sDesc shippingDisplay ${emptyListMode ? 'emptyListMode' : ''}`}>        
            <h5>{`- - - -`}</h5>
          </div>
          <div className={`shippingItem sPrice shippingLabel`}>
            <h5>{labels.priceLabelShippingLabels}</h5>
          </div>
          <div className={`shippingItem sPrice shippingDisplay`}>
            <h5>{labels.dropdownBlankContinentShippingLabels}</h5>
          </div>
        </>
        :
        <>
          <div className={`shippingItem sDesc shippingLabel`}>        
            <h5 dangerouslySetInnerHTML={{ __html: labels.shippingTimeLabelShippingLabels }}></h5>
            {/* <h5>{labels.shippingTimeLabelShippingLabels}</h5> */}
          </div>
          <div className={`shippingItem sDesc shippingDisplay`}>        
            <h5>{time}</h5>
          </div>
          <div className={`shippingItem sPrice shippingLabel`}>
            <h5>{labels.priceLabelShippingLabels}</h5>
          </div>
          <div className={`shippingItem sPrice shippingDisplay`}>
            <h5>{`${typeof price !== 'undefined' ? price : ''} ${currentLang === 'pl-PL' ? 'pln' : 'eur'}`}</h5>
          </div>
        </>
        }

        <div className={`shippingItem sPrice shippingLabel`}>
          <h5>{labels.infoField1ShippingLabels}</h5>
        </div>
        <div className={`shippingItem sPrice shippingDisplay spec`}>
          <h5>{labels.infoField2ShippingLabels}</h5>
        </div>

        <div className={`shippingItem sPrice shippingLabel`}>
          <h5>{labels.infoField3ShippingLabels}</h5>
        </div>
        <div className={`shippingItem sPrice shippingDisplay spec`}>
          <h5>{labels.infoField4ShippingLabels}</h5>
        </div>

      </div>

      {pageContentHtml &&
        <div className="box shipping wpContent" >
          <div className={`wpContent`} ref={pageContentHtml && contentRef} dangerouslySetInnerHTML={{ __html: pageContentHtml }}></div>
        </div>
      }

      <div 
        className={`heightControl`}
        style={{
          width: 0,
          height: `${heightControl}px`,
          transition: 'height 144ms ease-in',
        }}
      >
      </div>

    </>
  )
}
export default Regions