import React, { useContext, useEffect, useRef } from 'react'
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from 'gatsby'
import { useSwipeable } from 'react-swipeable'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import InterSectionTransition from 'src/components/layouts/interSectionTransition'
// import TransitionCamo from 'src/components/layouts/transitionCamo'
import Image from 'gatsby-image'

import Header from 'src/components/common/header'
import Footer from 'src/components/common/footer'

import SvgFilterBlur from 'src/components/tools/svgFilterBlur'
import SvgBackMap from 'src/components/shippingData/svgBackMap'

import LayoutPortalTop from 'src/components/layouts/layoutPortalTop'
import LayoutPortalBottom from 'src/components/layouts/layoutPortalBottom'

import WindowDriver from 'src/components/tools/windowDriver'


import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

import logo from '../../../static/logoWhite.svg'

import { navigate } from "gatsby"

// const BrandLayout = ({ children, pageContext, page, subpage }) => {
const BrandLayout = ({ children, pageContext, page }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BrandLayoutQuery {

      siteSlogan: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "brand-slogan"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }

      headerMenu: allWpMenu(filter: { name: { eq: "brand-mainmenu" } }) {
        edges {
          node {
            name
            id
            menuItems {
              nodes {
                label
                parentId
                childItems {
                  nodes {
                    id
                  }
                }
                path
                id
                cssClasses
                order
              }
            }
          }
        }
      }

      file(relativePath: { eq: "brand/background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      switchMenu: allWpMenu(filter: { name: { eq: "sectionSwitch" } }) {
        edges {
          node {
            name
            id
            menuItems {
              nodes {
                label
                parentId
                cssClasses
                childItems {
                  nodes {
                    id
                  }
                }
                path
                id
                cssClasses
              }
            }
          }
        }
      }
      privacyPolicy: wpPage(AcfGatsbyId: {pageid: {eq: "privacy-policy"}}) {
        slug
      }
    }
  `)

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const siteSlogan = graphqlResult.siteSlogan.acfgeneralinfos_layout.lineGenralInfosInfo

  const sidewaysLinkPrev = useRef(null)
  const sidewaysLinkNext = useRef(null)

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right' && sidewaysLinkPrev.current) sidewaysLinkPrev.current.click()
      else if (eventData.dir === 'Left' && sidewaysLinkNext.current) sidewaysLinkNext.current.click()
    },
    delta: 20
  })


  // function orientationInitial() {
  //   return state.windowWidth > 1078 ? 'desktop' : 'mobile'
  // }
  // const[orientation, setOrientation] = useState(() => orientationInitial())
  // useLayoutEffect(() => {
  //   setOrientation(state.windowWidth > 1078 ? 'desktop' : 'mobile')
  // }, [state.windowWidth])


  /*    FOOTER MEASURE FOR ANIMATION    */
  // function footerInitial() {
  //   return 0
  // }
  // const fHeightRef = useRef(null)
  // const[footerHeight, setFooterHeight] = useState(() => footerInitial())
  // useLayoutEffect(() => {
  //   const fHeight = fHeightRef.current.getBoundingClientRect()
  //   setFooterHeight(parseInt(fHeight.height))
  // }, [state.windowWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        state.menu && dispatch({ type: 'TOGGLE_MENU' })
      }
    })
  }, [state.menu])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (page.AcfGatsbyId.pageid === 'start' && action === 'POP' && state.playStartBrand === true) {
        dispatch({ type: 'PLAY_START_BRAND' })
        navigate(`${pageContext.sitePath}`)
      }
    })
  }, [state.playStartBrand])  // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(page)

  
  return (
    <div className="wrapper" {...handlers} style={state.playStartBrand ? {maxHeight:'100vh'} : {}}>

      <LayoutPortalTop position={`relative`} level={state.playStartBrand ? 'bottom' : 'uberTop'}>
      <div className={`headingPortal`}>
          <div className={`container`}>
            <div className="logo">
              <AniLink
                // to={`/${pageContext.realSlugs[pageContext.siteSlug]}`}
                to={`/`}
                duration={2}
                direction="right"
                swipe
                entryOffset={400}
                // state={{ prevPath: pageContext.siteSlug }}
              >
                <img src={logo} alt="Modern Forms logo" style={{width:'60px',display:'block'}}></img>
              </AniLink>
            </div>
            <span className="site-header-mark">
              {siteSlogan}
            </span>
          </div>
        </div>
        <Header pageContext={pageContext} pageid={page.AcfGatsbyId.pageid} headerMenu={graphqlResult.headerMenu} switchMenu={graphqlResult.switchMenu} sidewaysLinkPrev={sidewaysLinkPrev} sidewaysLinkNext={sidewaysLinkNext} />
      </LayoutPortalTop>

      <LayoutPortalTop level="bottom" position={`fixed`}>
        {(page.AcfGatsbyId.pageid !== 'shipping' && !page.featuredImage) && <div className="bg-image-wrapper">
          <Image alt="" style={{ filter: "url('#svgBlur')" }} fluid={graphqlResult.file.childImageSharp.fluid} />
        </div>}

        {(page.AcfGatsbyId.pageid === 'shipping' && state.windowWidth > 1061) ?
          <SvgBackMap /> : ''
        }

        {(page.featuredImage && !page.subpage && (page.AcfGatsbyId.pageid !== 'technology')) &&
          <div className={``} >
            <Image
              className={'defaultBackgroundImg'}
              style={{ 
                position: 'fixed', 
                top: `${(page.AcfGatsbyId.pageid !== 'career') ? '125px' : '25px'}`, 
                // top: '25px', 
                right: '0px',
                zIndex: '0', 
                // height: 'calc( 100% - 100px )' ,
                minHeight: '100vh',
                
              }}
              fluid={page.featuredImage.node.localFile.childImageSharp.fluid}
              alt={typeof page.featuredImage.node.altText !== 'undeined' ? page.featuredImage.node.altText : ''}
              title={typeof page.featuredImage.node.title !== 'undeined' ? page.featuredImage.node.title : false}
            />
          </div>
        }

        {page.AcfGatsbyId.pageid === 'technology' &&

            <div class="video-wrapper" style={{overflow: 'hidden', maxWidth: '100%'}}>
              <div class="frame-container" style={{position: 'fixed', paddingBottom: '56.25%', paddingTop: '25px', width: '300%', left: '-100%'}}>
                  <iframe className={`defaultBackgroundImg`} style={{position: 'absolute', top: '125px', left: '0', width: '100%', height: '100%'}} src="https://www.youtube.com/embed/POxcf0ANKXg?autoplay=1&amp;mute=1&amp;controls=0&amp;loop=1&amp;start=1&amp;playlist=POxcf0ANKXg" frameborder="0">
                  </iframe>
              </div>
            </div>
        }


        {/* <span style={{display:"none"}}>{siteSlogan}</span> */}
      </LayoutPortalTop>

      <main className="main">

        <div className={`container `} style={state.playStartBrand ? {width:'100vw'} : {}}>
          <div className={`placeholderForHeading`}></div>
          {children}
        </div>

      </main>

      <LayoutPortalBottom 
      
        level={(state.playStartBrand) ? 'bottom' : 'uberTop'} 
        // level={(state.playStartBrand || state.menu) ? 'bottom' : 'uberTop'} 

        // position={`fixed`}
      >

        <Footer pageContext={pageContext} logo={logo} />

        {/* <InterSectionTransition /> */}
        <WindowDriver />

      </LayoutPortalBottom>

      {/* <TransitionCamo /> */}

      <SvgFilterBlur />

    </div>
  )
}
export default BrandLayout